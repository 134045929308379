const fallbackImageUrl = '/no-image.png';

const fetchImageErrorCallback = (event: any, width?: string, height?: string) => {
    if (event.target.src !== fallbackImageUrl) {
        if (width) {
            event.target.style.width = width;
        }
        if (height) {
            event.target.style.height = height;
        }
        event.target.src = fallbackImageUrl;
    }
};

export default fetchImageErrorCallback;
