import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import AboutCard from '../../../ui/cards/about-card/AboutCard';
import BadgeListCard from '../../../ui/cards/badge-list-card/BadgeListCard';
import PageTitle from '../../../ui/general/page-title/PageTitle';
import SkillList from '../../../ui/general/skill-list/SkillList';
import DottedCircle from '../../../ui/general/dotted-circle/DottedCircle';
import '../../../css/Shepherd.scss';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { IBadgeSkillLevel } from '../../../interfaces/IBadgeSkillLevel';
import { StyledTab, StyledTabs } from '../../../css/CommonComponents';
import { useOutcomeStateValue } from '../../../contexts/OutcomeContext';
import RecommendationCardItem from '../../../ui/cards/recommendation-card/recommendation-card-item/RecommendationCardItem';
import SomethingWentWrongCard from '../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { makeSkillRoute, routes } from '../../routes';
import { createUrl } from '../../../utils/createUrl';
import TabPanel from '../../../ui/general/tab-panel/TabPanel';
import {
    SkillListWrapper,
    SkillPaperCountSpan,
    SkillPaperTitleSpan,
    SkillPaperTitleWrapper,
    SkillStepsWrapperPaper,
    RootInnerBox,
    NextStepsWrapper,
    NextStepsBox,
    SkillStepsBox,
    SkillPaperTitle,
    StepPaperTitleBox,
    TabTitle,
    SkillTabCount,
    RootBox,
    TabsWrapper
} from './Style';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import { EToastSeverity, useToastContextStateValue } from '../../../contexts/ToastContext';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../utils/loadImageGradually';

const Outcome: FC = () => {
    const [isPopupMode, setPopupMode] = useState<boolean>(false);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const { t } = useTranslation();
    const [selectedTabValue, setSelectedTabValue] = useState<number>(0);
    const navigate = useNavigate();
    const {
        userOutcomeData,
        isLoadingOutcomeData,
        isOutcomeRefetching,
        toggleOutcomeAssign,
        isError,
        refetchOutcomeData,
        nextStepRecommendations,
        isNextStepRecommendationsLoading
    } = useOutcomeStateValue();
    const { appendCrumb } = useCrumbsStateValue();
    const [searchParams] = useSearchParams();
    const { setToastMessage } = useToastContextStateValue();
    const location = useLocation();
    const { sendContentUsageRequest } = useOutcomeStateValue();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const successShareBadge = searchParams.get('successShareBadge');
        if (successShareBadge === '1' && location.state?.from === routes.LINKEDIN)
            setToastMessage({
                isOpen: true,
                message: t('badges.shareBadgeSuccess'),
                severity: EToastSeverity.SUCCESS
            });
    }, [searchParams]);

    useEffect(() => {
        const popupMode = localStorage.getItem('popupMode');
        setPopupMode(popupMode === '1');
    }, []);

    const onSkillSelect = (skillId: string) => {
        const pathname = makeSkillRoute(skillId);
        appendCrumb({
            name: userOutcomeData?.userSkills.find((skill) => skill.id.toString() === skillId)?.title || 'Skill',
            pathname: pathname
        });
        navigate(pathname);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabValue(newValue);
    };

    const nextStepsMemo = useMemo(() => {
        return nextStepRecommendations?.map((recommendation) => {
            return (
                <RecommendationCardItem
                    key={recommendation.id}
                    recommendation={recommendation}
                    isVisible
                    isLoading={isNextStepRecommendationsLoading}
                />
            );
        });
    }, [userOutcomeData, isPopupMode, nextStepRecommendations, isNextStepRecommendationsLoading]);

    const badgesMemo: IBadgeSkillLevel[] = useMemo(() => {
        const badges = [];
        if (userOutcomeData?.userLevelBadge)
            badges.push({
                badge: userOutcomeData.userLevelBadge,
                level: ESkillLevel.USER
            });
        if (userOutcomeData?.producerLevelBadge)
            badges.push({
                badge: userOutcomeData.producerLevelBadge,
                level: ESkillLevel.PRODUCER
            });
        if (userOutcomeData?.maxLevel === ESkillLevel.MASTER && userOutcomeData?.masterLevelBadge)
            badges.push({
                badge: userOutcomeData.masterLevelBadge,
                level: ESkillLevel.MASTER
            });
        return badges;
    }, [userOutcomeData]);

    const userOutcomeState = useMemo(() => {
        if (userOutcomeData?.assignment.level === ESkillLevel.MASTER) return userOutcomeData.assignment.masterState;
        if (userOutcomeData?.assignment.level === ESkillLevel.PRODUCER) return userOutcomeData.assignment.producerState;
        return undefined;
    }, [userOutcomeData]);

    const skillListMemo = useMemo(() => {
        return (
            <SkillList
                skills={userOutcomeData?.userSkills}
                skillClickHandler={onSkillSelect}
                isLoading={isLoadingOutcomeData || isOutcomeRefetching}
            />
        );
    }, [userOutcomeData, onSkillSelect, isLoadingOutcomeData, isOutcomeRefetching]);

    return (
        <RootBox>
            <RootInnerBox>
                <PageTitle
                    title={userOutcomeData?.title}
                    assigned={{
                        assignerId: userOutcomeData?.assignment.assignerId,
                        isAssigned: userOutcomeData
                            ? userOutcomeData?.assignment.level !== ESkillLevel.NONE
                            : undefined,
                        assignerName: userOutcomeData?.assignment.assignerName,
                        assignedDate: userOutcomeData?.assignment.date,
                        assignedTitle: t('outcome.inYourOutcomes'),
                        notAssignedTitle: t('outcome.notInYourOutcomes'),
                        assignTooltipText: t('tooltips.addOutcome'),
                        unassignTooltipText: t('tooltips.removeOutcome'),
                        streak: userOutcomeData?.assignment.streak,
                        state: userOutcomeState
                    }}
                    toggleAssign={toggleOutcomeAssign}
                    isError={isError}
                    leftSectionJSX={
                        <DottedCircle
                            numberOfDots={userOutcomeData?.userSkills?.length || 0}
                            numberOfInProgressDots={userOutcomeData?.numberOfInProgressSkills ?? 0}
                            numberOfAchievedDots={userOutcomeData?.numberOfAchievedSkills ?? 0}
                            numberOfNeedAttentionDots={userOutcomeData?.numberOfNeedAttentionSkills ?? 0}
                            isMaster={userOutcomeData?.assignment.level === ESkillLevel.MASTER}
                            isAssigned={userOutcomeData?.assignment.level !== ESkillLevel.NONE}
                        >
                            {userOutcomeData?.image ? (
                                <img
                                    alt='outcome'
                                    src={createUrl(userOutcomeData?.image)?.generatedUrl}
                                    onError={(e) => fetchImageErrorCallback(e, '70px', '70px')}
                                    onLoad={loadImageGraduallyHandler}
                                />
                            ) : (
                                <Skeleton variant='circular' width={70} height={70} />
                            )}
                        </DottedCircle>
                    }
                />
                <AboutCard
                    loading={isLoadingOutcomeData || isOutcomeRefetching}
                    title={t('outcome.contentCard.title')}
                    appIcons={userOutcomeData?.apps}
                    description={userOutcomeData?.description}
                    videoImage={userOutcomeData?.videoImage}
                    videoUrl={userOutcomeData?.videoUrl}
                    isError={isError}
                    errorRefetchCallback={refetchOutcomeData}
                    contentId={userOutcomeData?.id}
                    videoWatchedCallback={sendContentUsageRequest}
                />
                <BadgeListCard
                    badges={badgesMemo}
                    isLoading={isLoadingOutcomeData || isOutcomeRefetching}
                    isMasterTargetDisabled={userOutcomeData?.isMasterTargetControlDisabled}
                    isOutcomeAssigned={userOutcomeData?.assignment.level !== ESkillLevel.NONE}
                    isMasterLevelRequired={userOutcomeData?.masterLevelRequested ?? false}
                    isError={isError}
                    errorRefetchCallback={refetchOutcomeData}
                    assignmentLevel={userOutcomeData?.assignment.level}
                    outcomeId={userOutcomeData?.id}
                />
                <SkillStepsBox>
                    <SkillStepsWrapperPaper elevation={1}>
                        <SkillListWrapper className='skill-list-wrapper'>
                            {isError ? (
                                <SomethingWentWrongCard actionCallback={refetchOutcomeData} />
                            ) : (
                                <>
                                    {isLgDown ? (
                                        <>
                                            <TabsWrapper>
                                                <StyledTabs value={selectedTabValue} onChange={handleTabChange}>
                                                    <StyledTab
                                                        isActive={selectedTabValue === 0}
                                                        label={
                                                            <div>
                                                                <TabTitle isSelected={selectedTabValue === 0}>
                                                                    {t('outcome.contentCard.skills')}
                                                                </TabTitle>
                                                                {userOutcomeData?.userSkills && (
                                                                    <SkillTabCount isSelected={selectedTabValue === 0}>
                                                                        ({userOutcomeData?.userSkills.length})
                                                                    </SkillTabCount>
                                                                )}
                                                            </div>
                                                        }
                                                    />
                                                    <StyledTab
                                                        isActive={selectedTabValue === 1}
                                                        label={
                                                            <TabTitle isSelected={selectedTabValue === 1}>
                                                                {t('outcome.contentCard.nextStepsForThisOutcome')}
                                                            </TabTitle>
                                                        }
                                                    />
                                                </StyledTabs>
                                            </TabsWrapper>
                                            <TabPanel index={0} value={selectedTabValue}>
                                                {skillListMemo}
                                            </TabPanel>
                                            <TabPanel index={1} value={selectedTabValue}>
                                                <NextStepsBox>{nextStepsMemo}</NextStepsBox>
                                            </TabPanel>
                                        </>
                                    ) : (
                                        <SkillPaperTitle>
                                            <SkillPaperTitleWrapper>
                                                <SkillPaperTitleSpan variant='h6'>
                                                    {t('outcome.contentCard.skills')}
                                                </SkillPaperTitleSpan>
                                                {userOutcomeData?.userSkills && (
                                                    <SkillPaperCountSpan variant='body2'>
                                                        ({userOutcomeData?.userSkills.length})
                                                    </SkillPaperCountSpan>
                                                )}
                                            </SkillPaperTitleWrapper>
                                            {skillListMemo}
                                        </SkillPaperTitle>
                                    )}
                                </>
                            )}
                        </SkillListWrapper>

                        {!isLgDown && (
                            <NextStepsWrapper>
                                {userOutcomeData?.assignment.level !== ESkillLevel.NONE && (
                                    <>
                                        <StepPaperTitleBox>
                                            {t('outcome.contentCard.recommendations')}
                                        </StepPaperTitleBox>
                                        <NextStepsBox>{nextStepsMemo}</NextStepsBox>
                                    </>
                                )}
                            </NextStepsWrapper>
                        )}
                    </SkillStepsWrapperPaper>
                </SkillStepsBox>
            </RootInnerBox>
        </RootBox>
    );
};

export default Outcome;
