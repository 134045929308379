import { FC, useCallback, useMemo, useEffect, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { RovingTabIndexProvider } from 'react-roving-tabindex';
import { Skeleton } from '@mui/material';
import { IAppDTO } from '../../../interfaces/dtos/IAppDTO';
import useExpand from '../../../hooks/useExpand';
import {
    RootBox,
    FirstRowBox,
    AppIconImg,
    AppIconWrapperBox,
    AppsBox,
    RightSideWrapper,
    CollapsableRowBox,
    Description,
    InfoBox,
    InfoTextBox,
    ShowDescriptionBox,
    NumberOfUsersBox,
    SelectBox,
    MainContentWrapper,
    VideoDescriptionBox,
    BasicSkilListWrapper,
    CompanyFocusActionBox,
    CompanyFocusDescriptionWrapperBox,
    DescriptionSegment
} from './Style';
import NCheckbox from '../../../ui/form/n-checkbox/NCheckbox';
import BasicSkillList from '../../../ui/general/basic-skill-list/BasicSkillList';
import { IFullVideoUrlsResponse, createFullVideoUrls, createUrl } from '../../../utils/createUrl';
import { IAdminSkillVM } from '../../../interfaces/views/IAdminSkillVM';
import { useAdminOutcomesStateValue } from '../../../contexts/AdminOutcomesContext';
import ClipboardCheckIcon from '../../../assets/icons/ClipboardCheck';
import ClipboardUncheckIcon from '../../../assets/icons/ClipboardUncheck';
import { makeSkillRoute, routes } from '../../../pages/routes';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import Tooltip from '../../../ui/general/tooltip/Tooltip';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import VidstackVideo from '../../../ui/general/video/VidstackVideo';
import { lineBreakSplitter } from '../../../utils/lineBreakSplitter';
import { isFullLink } from '../../../utils/isFullLink';
import { accessibilityEnterSpaceKeyCallback } from '../../../utils/accessibilityUtils';

interface IProps {
    id: number;
    name: string;
    groupName?: string;
    appIcons: IAppDTO[];
    outcomeClickHandler?: (id: string) => void;
    description?: string;
    isCompanyFocus: boolean;
    translations?: {
        assignedTitle?: string;
        notAssignedTitle?: string;
        assignTooltipText?: string;
        unassignTooltipText?: string;
    };
    toggleAssignCallback?: (outcomeId: number, isAssigned: boolean) => void;
    index?: number;
    outcomeImage?: string;
    outcomeVideo?: string;
    outcomeVideoSnapshot?: string;
    isActionDisabled?: boolean;
    numberOfUsers: number;
    isLoading: boolean;
    skills: IAdminSkillVM[];
}

const AdminOutcomeCard: FC<IProps> = ({
    id,
    name,
    appIcons,
    outcomeClickHandler,
    description,
    index,
    outcomeImage,
    numberOfUsers,
    outcomeVideo,
    outcomeVideoSnapshot,
    isLoading,
    skills,
    isCompanyFocus
}) => {
    const { t } = useTranslation();
    const {
        changeSelectedOutcomesList,
        selectedOutcomesList,
        handleCompanyFocusChangeClick,
        isCompanyFocusChangeLoading
    } = useAdminOutcomesStateValue();
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: false
    });
    const navigate = useNavigate();
    const { appendCrumb, replaceLatestCrumb } = useCrumbsStateValue();
    const [searchParams] = useSearchParams();
    const [fullVideoUrls, setFullVideoUrls] = useState<IFullVideoUrlsResponse | undefined>();

    const descriptionMemo = useMemo(() => {
        if (!description) return '';
        const descriptionSegments = lineBreakSplitter(description);
        return descriptionSegments.map((segment, index) => {
            return <DescriptionSegment key={index}>{segment.trim()}</DescriptionSegment>;
        });
    }, [description]);

    useEffect(() => {
        const openId = searchParams.get('openId');
        if (openId && openId === id?.toString()) {
            expand();
            setTimeout(() => {
                const skillCardElements = document.getElementsByClassName(`basic-skill-list-item-${skills[0]?.id}`);
                if (skillCardElements && skillCardElements.length > 0) {
                    skillCardElements[0].scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, [searchParams]);

    const onSkillClick = useCallback((skillId: number) => {
        const skillRoute = makeSkillRoute(skillId);
        replaceLatestCrumb({
            name: 'Outcomes',
            pathname: `${routes.ADMIN_OUTCOMES}?openId=${id}`
        });

        appendCrumb({
            name: 'Organizational Outcomes',
            pathname: skillRoute
        });
        navigate(skillRoute);
    }, []);

    const onSelectOutcomeChange = () => {
        changeSelectedOutcomesList(id);
    };

    const outcomeBadgeMemo = useMemo(() => {
        if (skills)
            return (
                <img
                    width='70px'
                    height='70px'
                    alt='outcome'
                    src={createUrl(outcomeImage)?.generatedUrl}
                    onError={fetchImageErrorCallback}
                />
            );
        return <Skeleton variant='circular' height={70} width={70} />;
    }, [skills, outcomeImage]);

    useEffect(() => {
        if (outcomeVideo) {
            const urls = createFullVideoUrls(outcomeVideo);
            setFullVideoUrls(urls);
        }
    }, [outcomeVideo]);

    return (
        <RootBox className={`outcome-card-${index ?? 0}`}>
            <FirstRowBox>
                <SelectBox id={`select-box-${index ?? 0}`}>
                    <NCheckbox
                        onChange={onSelectOutcomeChange}
                        isChecked={
                            selectedOutcomesList.findIndex(
                                (selectedOutcomesList) => selectedOutcomesList.outcome.id === id
                            ) !== -1
                        }
                        size='14px'
                    />
                </SelectBox>
                {outcomeBadgeMemo}
                <MainContentWrapper>
                    <InfoBox
                        onClick={() => {
                            if (outcomeClickHandler) outcomeClickHandler(id.toString());
                        }}
                    >
                        <InfoTextBox variant='subtitle2'>{name}</InfoTextBox>
                        <AppsBox>
                            {appIcons && (
                                <AppIconWrapperBox>
                                    {appIcons.map((appIcon) => {
                                        return (
                                            <AppIconImg
                                                key={appIcon.name}
                                                alt={appIcon.name}
                                                src={createUrl(appIcon.icon)?.generatedUrl}
                                                onError={fetchImageErrorCallback}
                                            />
                                        );
                                    })}
                                </AppIconWrapperBox>
                            )}
                        </AppsBox>
                    </InfoBox>
                    <RightSideWrapper id='outcome-card-info-box'>
                        <NumberOfUsersBox>{numberOfUsers} Users</NumberOfUsersBox>
                        <CompanyFocusDescriptionWrapperBox>
                            <Tooltip
                                title={
                                    isCompanyFocus
                                        ? t('tooltips.removeAsOrganizationPriority')
                                        : t('tooltips.setAsOrganizationPriority')
                                }
                            >
                                <CompanyFocusActionBox
                                    isActive={isCompanyFocus}
                                    onClick={() =>
                                        !isCompanyFocusChangeLoading &&
                                        handleCompanyFocusChangeClick(id, !isCompanyFocus)
                                    }
                                    tabIndex={0}
                                    onKeyDown={(e: KeyboardEvent<any>) =>
                                        accessibilityEnterSpaceKeyCallback(e, () => {
                                            if (!isCompanyFocusChangeLoading)
                                                handleCompanyFocusChangeClick(id, !isCompanyFocus);
                                        })
                                    }
                                >
                                    {isCompanyFocus ? (
                                        <ClipboardCheckIcon hoverEffect />
                                    ) : (
                                        <ClipboardUncheckIcon hoverEffect />
                                    )}
                                </CompanyFocusActionBox>
                            </Tooltip>
                            <ShowDescriptionBox
                                onClick={isExpanded ? collapse : expand}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterSpaceKeyCallback(e, () => {
                                        if (isExpanded) collapse();
                                        else expand();
                                    })
                                }
                            >
                                <span>{isExpanded ? t('skill.hideDescription') : t('skill.showDescription')}</span>
                            </ShowDescriptionBox>
                        </CompanyFocusDescriptionWrapperBox>
                    </RightSideWrapper>
                </MainContentWrapper>
            </FirstRowBox>
            {isExpanded && (
                <CollapsableRowBox>
                    <VideoDescriptionBox>
                        <Description variant='body2'>{descriptionMemo}</Description>
                        {outcomeVideo && (
                            <VidstackVideo
                                videoUrl={
                                    outcomeVideo && isFullLink(outcomeVideo)
                                        ? outcomeVideo
                                        : fullVideoUrls?.videoUrl || ''
                                }
                                videoImage={outcomeVideoSnapshot ? createUrl(outcomeVideoSnapshot)?.generatedUrl : ''}
                                captionUrl={fullVideoUrls?.captionUrl}
                            />
                        )}
                    </VideoDescriptionBox>
                    <BasicSkilListWrapper>
                        <RovingTabIndexProvider options={{ direction: 'vertical' }}>
                            <BasicSkillList
                                skillClickHandler={onSkillClick}
                                isLoading={isLoading}
                                skills={skills.map((skill) => {
                                    return {
                                        id: skill.id,
                                        title: skill.title,
                                        apps: skill.apps
                                    };
                                })}
                            />
                        </RovingTabIndexProvider>
                    </BasicSkilListWrapper>
                </CollapsableRowBox>
            )}
        </RootBox>
    );
};

export default AdminOutcomeCard;
